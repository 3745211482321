<template>
  <v-overlay :value="true">
    <v-progress-circular
      :size="100"
      :width="7"
      color="black"
      class="loader"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>
<script>
export default {
  name: "Loader",
};
</script>
<style scoped>

</style>