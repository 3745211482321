<template>
  <div>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      color="deep-purple accent-4"
    ></v-progress-linear>
    <Layout
      @filter="filter"
      :length="total_pages"
      :itemName="itemName"
      class="
        v-data-table
        elevation-1
        v-data-table--has-top v-data-table--has-bottom v-data-table--dense
        theme--light
      "
    >
      <vue-table
        :http="util.http"
        v-bind="$props"
        @dataLoading="dataLoading"
        @dataLoaded="dataLoaded"
      >
        <template #headerLeft="">
          <!-- <v-combobox v-bind="basic"></v-combobox> -->
        </template>
        <template #search>
          <!-- <v-text-field
          type="text"
          v-model="search"
          @input="searchThis"
          v-bind="basic"
          append-icon="mdi-magnify"
        /> -->
        </template>
        <template #pagination=""
          >.
          <!-- <v-pagination
            v-model="page"
            :length="pdata.page"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination> -->
        </template>
        <template #jumpToPage> </template>
      </vue-table>
    </Layout>
  </div>
</template>
<script>
import util from "@/util/util";
import vueTable from "@/lib/geeklearners-vue-table";
import { util as p } from "@/lib/geeklearners-vue-table";
import Layout from "@/components/Contact/PersonalContactsInfos/Layout.vue";
export default {
  components: { vueTable, Layout },
  props: { ...vueTable.props, itemName: String },
  data() {
    return {
      util,
      search: "",
      page: 1,
      loading: true,
      basic: util.input.basic,
      total_pages: 1,
    };
  },
  methods: {
    dataLoaded(d) {
      this.total_pages = d.last_page;
      console.log(d);
    },
    searchThis() {
      p.event.$emit("search", this.search);
    },
    dataLoading(loading) {
      this.loading = loading;
    },
    filter(cri) {
      p.event.$emit("filter", cri);
    },
  },
  watch: {
    page: function() {
      p.event.$emit("pageChanged", this.page);
    },
  },
};
</script>
