<template>
    <v-card class="py-5">
      <div class="text-center">
        <img src="@/assets/delete-btn.png" style="width: 100px" />
      </div>
      <v-card-text class="text "
        >
        <h3 class="text-center">Are You Sure Want to Delete ?</h3>
        </v-card-text
      >
        <v-card-actions class="mt-3 justify-center" style="padding-left: 24px">
          <div class="text-center">
            <v-btn
              color="#d1d1d1 "
              min-width="90px"
              @click="$emit('cancel')"
              >No</v-btn
            >
            <v-btn
              color="#1d2b58"
              min-width="90px"
              class="button-color ml-5"
              @click="deleteResource"
              :loading="loading"
              >Yes</v-btn
            >
            <v-spacer></v-spacer>
          </div>
        </v-card-actions>
    </v-card>
</template>
<script>
import util from "@/util/util";
export default {
  data() {
    return {
      loading: false,
    };
  },
  props: {
    resource: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  methods: {
    deleteResource() {
      this.loading = true;
      util
        .http({
          url: this.$props.resource + "/" + this.$props.id,
          method: "delete",
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 1) {
            util.notify(1, "Deleted Successfully");
            this.$emit("reload-resource", true);
          } else {
            util.notify(0, res.data.errors[0]);
          }
        });
    },
  },
};
</script>
<style scoped>
.button-color {
  color: #fff !important;
}
</style>
