<template lang="">
  <div class="project-boards">
    <div class="row project-name-title justify-space-between align-center">
      <h4 class="text-uppercase">{{ title }}</h4>
      <!-- <v-icon color="white">mdi-dots-vertical</v-icon> -->
      <slot name="menu"></slot>
    </div>

    <div class="mt-7">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style lang="scss" scoped>
.project-name-title {
  background: #1d2b58;
  color: #fff;
  padding: 5px 10px !important;
}
.create-project-btn {
  background: #1d2b58;
  color: #fff;
  padding: 7px 25px;
  margin-top: 30px;
}
</style>
