<template>
  <div
    class="alert"
    style="text-align:left"
    show
    :class="'alert-' + variant"
    v-if="messages && Array.isArray(messages) && messages.length > 0"
  >
    <ul>
      <li v-for="(message, i) in messages" :key="i + '_' + message">
        {{ message }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    variant: {
      type: String,
      default: "success",
    },
  },
};
</script>
<style scoped>
.alert {
  padding: 10px;
}
ul > li {
  list-style: none;
}
</style>
